@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
  
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 7px;
  
}

/* Track */
::-webkit-scrollbar-track {
  background: #000000;
  border-radius: 5px
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: 5px
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #fffcfc;
}
